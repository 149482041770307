// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sectionStyles {
    display: flex;
    justify-content: space-between; 
    padding: 2rem 1rem;
    gap: 1rem;
}

.featureContainer {
    flex: 1 1; 
    max-width: 400px;
    text-align: center;
    border-radius: 8px; 
    padding: 1rem; 
    margin: 0 0.5rem; 
}

.imageContainer {
    width: 200px; 
    height: 200px; 
    object-fit: cover; 
    margin-bottom: 1rem;
    border: 1px solid #4287f5; 
}
`, "",{"version":3,"sources":["webpack://./src/components/homePage/styles/features.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,SAAO;IACP,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":[".sectionStyles {\n    display: flex;\n    justify-content: space-between; \n    padding: 2rem 1rem;\n    gap: 1rem;\n}\n\n.featureContainer {\n    flex: 1; \n    max-width: 400px;\n    text-align: center;\n    border-radius: 8px; \n    padding: 1rem; \n    margin: 0 0.5rem; \n}\n\n.imageContainer {\n    width: 200px; \n    height: 200px; \n    object-fit: cover; \n    margin-bottom: 1rem;\n    border: 1px solid #4287f5; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
