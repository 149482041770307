// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-color-1: #4287f5;
    --primary-color-2: #d6e4fc;
    --primary-color-1-dark: #1b468a;
    --primary-color-1-light: #f1f5fd;
    --secondary-color: #b8b8b8;
    --accent-color: #57e57f;
    --text-color: #050316;
    --text-color-2: #fbfbfe;
    --background-color: #fbfbfe;
  }`, "",{"version":3,"sources":["webpack://./src/styles/globalStyles.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,0BAA0B;IAC1B,+BAA+B;IAC/B,gCAAgC;IAChC,0BAA0B;IAC1B,uBAAuB;IACvB,qBAAqB;IACrB,uBAAuB;IACvB,2BAA2B;EAC7B","sourcesContent":[":root {\n    --primary-color-1: #4287f5;\n    --primary-color-2: #d6e4fc;\n    --primary-color-1-dark: #1b468a;\n    --primary-color-1-light: #f1f5fd;\n    --secondary-color: #b8b8b8;\n    --accent-color: #57e57f;\n    --text-color: #050316;\n    --text-color-2: #fbfbfe;\n    --background-color: #fbfbfe;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
