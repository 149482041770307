// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Login.css */
.login-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 20px;
    border-radius: 8px;
  }
  `, "",{"version":3,"sources":["webpack://./src/auth/pages/login.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,qCAAqC;IACrC,aAAa;IACb,kBAAkB;EACpB","sourcesContent":["/* Login.css */\n.login-container {\n    min-height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .card {\n    box-shadow: 0 4px 8px rgba(0,0,0,0.1);\n    padding: 20px;\n    border-radius: 8px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
