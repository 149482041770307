// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/medic3.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    position: relative;
    min-height: 90vh;
    height: 100%;
    padding: 20px;
    border-radius: 10px;
}

.background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 0.5;
    z-index: -1;
}

.content {
    position: relative;
    z-index: 1;
}

.content-card {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.bg-doctor {
    max-width: 85%;
}

.card {
    max-width: 80%;
    left:10%
}`, "",{"version":3,"sources":["webpack://./src/auth/pages/orders.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,yDAAkD;IAClD,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,4BAA4B;IAC5B,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,wCAAwC;IACxC,oCAAoC;AACxC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd;AACJ","sourcesContent":[".container {\n    position: relative;\n    min-height: 90vh;\n    height: 100%;\n    padding: 20px;\n    border-radius: 10px;\n}\n\n.background-overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-image: url(\"/public/images/medic3.png\");\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    background-attachment: fixed;\n    opacity: 0.5;\n    z-index: -1;\n}\n\n.content {\n    position: relative;\n    z-index: 1;\n}\n\n.content-card {\n    background-color: rgba(255, 255, 255, 1);\n    border: 1px solid rgba(0, 0, 0, 0.1);\n}\n\n.bg-doctor {\n    max-width: 85%;\n}\n\n.card {\n    max-width: 80%;\n    left:10%\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
