// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.container {
    padding: 2rem;
  }
  
  .hero {
    background-color: var(--primary);
    color: white;
    padding: 4rem 2rem;
    text-align: center;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    padding: 2rem 0;
  }
  
  .feature-item {
    max-width: 300px;
    text-align: center;
  }
  
  .testimonials {
    background-color: var(--lightGray);
    padding: 2rem 0;
    text-align: center;
  }
  
  .footer {
    background-color: var(--primary);
    color: white;
    padding: 1rem;
    text-align: center;
  }`, "",{"version":3,"sources":["webpack://./src/auth/pages/home.css"],"names":[],"mappings":";AACA;IACI,aAAa;EACf;;EAEA;IACE,gCAAgC;IAChC,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,kCAAkC;IAClC,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,gCAAgC;IAChC,YAAY;IACZ,aAAa;IACb,kBAAkB;EACpB","sourcesContent":["\n.container {\n    padding: 2rem;\n  }\n  \n  .hero {\n    background-color: var(--primary);\n    color: white;\n    padding: 4rem 2rem;\n    text-align: center;\n  }\n  \n  .features {\n    display: flex;\n    justify-content: space-around;\n    padding: 2rem 0;\n  }\n  \n  .feature-item {\n    max-width: 300px;\n    text-align: center;\n  }\n  \n  .testimonials {\n    background-color: var(--lightGray);\n    padding: 2rem 0;\n    text-align: center;\n  }\n  \n  .footer {\n    background-color: var(--primary);\n    color: white;\n    padding: 1rem;\n    text-align: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
